import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit {

  message = new FormGroup({
    body: new FormControl('', Validators.required)
  })

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.firebaseService.addCustomMessage(this.message.value.body);
    this.message.reset();
  }

}
