import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {

  id: string
  subscription: Subscription
  booking: any
  timeSlots$: Observable<any>

  selectedTime: string
  selectedSlot: any
  status: any
  location: string
  showInfo: boolean
  error: boolean

  constructor(private route: ActivatedRoute, private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.timeSlots$ = this.firebaseService.getCollectionSnapshot('timeslots')
    this.id = this.route.snapshot.paramMap.get('id')
    this.firebaseService.timeSlotsStatus().then((res) => {

      console.log(res);

      this.status = undefined
      this.status = res

    })
    this.firebaseService.getDocumentValueChanges('bookings', this.id)
      .subscribe((booking) => {
        if (booking.userID) {
          this.booking = booking;
          if (booking.slotID) {
            this.selectedTime = booking.slotID
          }
          if (booking.location) {
            this.location = booking.location
          }
        } else {
          this.error = true
        }

      })
  }

  setLocation(location: string) {
    this.location = location
  }

  saveLocation(location, name) {
    this.firebaseService.updateBooking(this.id, this.selectedTime, { location })
    // Log location update

    const message = `Location changed to ${location}`

    this.sendLog(message, name)

  }

  select(slot, name) {
    console.log('selected')
    this.selectedTime = slot.id
    this.selectedSlot = slot

    this.firebaseService.updateBooking(this.id, this.selectedTime, { slotID: slot.id })
    // Log timeslot update

    const message = 'Timeslot updated'

    this.sendLog(message, name)


  }

  sendLog(message, user) {
    const data = {
      message,
      user,
      type: 'system',
      timestamp: Date.now(),
      collection: 'bookings',
      parentID: this.id
    }

    this.firebaseService.addCustomLog(data)


  }



  checkStatus(id: string, limit: number) {


    //console.log('current status: ' + this.status[id])
    //console.log('limit: ' + limit)

    if (this.status[id] >= limit) return true
    else return false
  }

  timeStamp(time: any) {
    return {
      from: time.seconds * 1000,
      to: (time.seconds * 1000) + ((3600 * 1000) * 3)
    }
  }

  checkThanksMessage() {
    if (this.location === 'office') return true
    else if (this.location === 'home' && this.selectedTime) return true
  }

}
