import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-list-bookings',
  templateUrl: './list-bookings.component.html',
  styleUrls: ['./list-bookings.component.scss']
})
export class ListBookingsComponent implements OnInit {

  bookings$:Observable<any>

  constructor(private stateService: StateService, private firebaseService:FirebaseService) { }

  ngOnInit(): void {
    this.stateService.loadBookings()
    this.bookings$ = this.firebaseService.getCollectionSnapshot('bookings')

    
  }

  save(booking) {
    this.firebaseService.updateDocument('bookings', booking.id, booking)
    .then(() => console.log('booking updated'))
  }

}
