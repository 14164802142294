import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-user-booking',
  templateUrl: './user-booking.component.html',
  styleUrls: ['./user-booking.component.scss']
})
export class UserBookingComponent implements OnInit {

  @Input() userID:string
  booking$:Observable<any>

  constructor(private firebaseService:FirebaseService) { }

  ngOnInit(): void {
    this.booking$ = this.firebaseService.getBookings(this.userID).valueChanges()
  }

  getLocation(location:string) {
    if(!location) {
      return 'not selected'
    } else {
      return location
    }
  }

}
