import { Component, Input, OnInit } from "@angular/core";
import { FirebaseService } from "../../services/firebase.service";

@Component({
  selector: "app-in-progress",
  templateUrl: "./in-progress.component.html",
  styleUrls: ["./in-progress.component.scss"],
})
export class InProgressComponent implements OnInit {
  @Input() deviceID: string;
  tasks: any;
  inProgress: boolean;
  inProgressBy: string;
  done: boolean;
  doneBy: string;

  constructor(private firebaseService: FirebaseService) {}

  ngOnInit(): void {
    const sub = this.firebaseService
      .getDocument(`tasklists/dOxH6P9ntWiceXoaDmeG/entities/${this.deviceID}`)
      .subscribe((doc: any) => {
        if (doc.tasks) {
          if (doc.tasks[0]) this.checkInProgress(doc.tasks[0]);
          if (doc.tasks[41]) this.checkDone(doc.tasks[45]);
        }
      });
  }

  checkInProgress(task) {
    if (task) {
      if (task.status === "in progress" || task.status === "done") {
        this.inProgress = true;
        this.inProgressBy = task.user;
      }
    }
  }

  checkDone(task) {
    if (task) {
      if (task.status === "done") {
        this.done = true;
        this.doneBy = task.user;
      }
    }
  }
}
