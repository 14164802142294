import { Component, OnInit } from '@angular/core';
import { auditTime, map, subscribeOn } from 'rxjs/operators';
import {FirebaseService} from '../../services/firebase.service'
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-crate-tasklist',
  templateUrl: './crate-tasklist.component.html',
  styleUrls: ['./crate-tasklist.component.scss']
})
export class CrateTasklistComponent implements OnInit {

  name:string
  tasks:any[] = []

  loadedTasklist:any
  tasklistId = 'dOxH6P9ntWiceXoaDmeG'

  constructor(private firebaseService: FirebaseService, private stateService: StateService) { }

  ngOnInit(): void {
   // this.remove('twJ8SXpcE3PK1OtiNS1a')

   this.firebaseService.getDocumentValueChanges('tasklists', this.tasklistId)
   .subscribe((list) => {
     this.name = list.name;
     const keys = Object.keys(list.tasks)
     
     for (const key of keys) {
       this.tasks[key] = list.tasks[key]
     }
   })

  }

  addTask() {
    this.tasks.push({
      label: '',
      desc: '',
      textHook: false,
      textMessage: '',
      id: this.tasks.length
    })
  }

  addDivider() {
    this.tasks.push({
      label: '',
      desc: '',
      textHook: false,
      textMessage: '',
      divider: true,
      id: this.tasks.length
    })
  }

  delete(i) {
    this.tasks.splice(i, 1)
  }

  move(old_index:number, new_index:number) {
    while (old_index < 0) {
        old_index += this.tasks.length;
    }
    while (new_index < 0) {
        new_index += this.tasks.length;
    }
    if (new_index >= this.tasks.length) {
        var k = new_index - this.tasks.length;
        while ((k--) + 1) {
            this.tasks.push(undefined);
        }
    }
     this.tasks.splice(new_index, 0, this.tasks.splice(old_index, 1)[0]);  
   return this.tasks;
}

moveUp(i) {
  const ni = i - 1;
  console.log(this.move(i, ni))
}
  
moveDown(i) {
  const ni = i + 1;
  console.log(this.move(i, ni))
}


  saveTasklist(){
    console.log(this.tasks)
    this.firebaseService.updateDoc(`tasklists/${this.tasklistId}`, {tasks: this.tasks, name: this.name}).then(() => console.log('tasklist updated'))
  }


  save() {

    this.stateService.loadDevices()

    const tasklist = {tasks: this.tasks, name: this.name}
    console.log(tasklist)

    let taskID:string


    const addToFirebase = async (deviceID:string) => {
            // Add ID to device
            await this.firebaseService.addTasklist(taskID, deviceID)
            .then(() => console.log('updated'))
      
            // Add device as entity
            await this.firebaseService.createDocumentPath(`tasklists/${taskID}/entities/${deviceID}`, {type: 'device'})
            .then(() => console.log('device added as entity'))
    }

    const loopOverDevices = async (devices) => {
      
        for (let i = 0; i < devices.length; i++) {
          console.log(devices[i].id)
          await addToFirebase(devices[i].id)
        }
    }


    // Add tasklist to Firebase
    this.firebaseService.createDocument('tasklists', tasklist)
    .then((doc) => {
      taskID = doc.id;

      // Get all users
     const sub = this.stateService.getDevices().pipe(
        auditTime(1000))
        .subscribe((devices => {
          loopOverDevices(devices)
        }))


    })



  }

  remove(id) {

    //this.stateService.loadDevices()

    console.log('removing')


    const removeFromFirebase = async (deviceID:string) => {
      // Add ID to device
      await this.firebaseService.removeTaskList(id, deviceID)
      .then(() => console.log('removed'))

}

const loopOverDevices = async (devices) => {
  sub.unsubscribe()
  for (let i = 0; i < devices.length; i++) {
    console.log(devices[i].id)
    await removeFromFirebase(devices[i].id)
  }
}


          // Get all users
   const sub = this.stateService.getDevices().pipe(
            auditTime(1000))
            .subscribe((devices => {
              loopOverDevices(devices)
            }))
  }

}
