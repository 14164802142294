import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { ElectronService } from '../../core/services';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {

  devices$: Observable<any>
  devices: any
  filter: string

  hideDone:boolean = false

  openIds = []
  openPwdIds = []
  migrationID:string

  order = 'assignedTo.name'

  constructor(private firebaseService: FirebaseService, private router: Router, private electron: ElectronService, private stateService: StateService) { }

  ngOnInit(): void {
    this.migrationID = this.stateService.migrationID
    this.stateService.loadDevices()
    this.devices$ = this.stateService.getDevices()
    this.devices$.pipe(auditTime(500)).subscribe(devices => {
      this.devices = []
      devices.forEach(device => {
        this.devices.push(device)
      });
    })
  }

  openNote(id: string, collection: string) {
    if (this.electron.isElectron) {
      this.electron.ipcRenderer.send('new', { path: `notepad;id=${id};collection=devices` });
    } else {
      this.router.navigate(['notepad', { id: id, collection: 'devices' }])
    }

    console.log('opening window')

  }

  orderDevice() {
    this.order = 'hostName'
  }
  orderName() {
    this.order = 'assignedTo.name'
  }

  expandAll(devices) {
    devices.forEach((device: any) => {
      device.expanded = true
    })
  }

  setError(device) {
    let error
    if (device.error) error = false
    if (!device.error) error = true

    this.firebaseService.updateDocument('devices', device.id, {error: error})
  }

  setDone(device) {
    let done
    if (device.done) done = false
    if (!device.done) done = true

    this.firebaseService.updateDocument('devices', device.id, {done: done})
  }

  closeAll(devices) {
    devices.forEach((device: any) => {
      device.expanded = false
    })
  }

  display(done) {
    if (done && this.hideDone) {
      return false
    } else {
      return true
    }
  }

  toggle(id) {
    if (this.checkState(id)) {
      //Close
      this.close(id)
    } else {
      //Open
      this.open(id)
    }
  }

  togglePwd(id) {
    if (this.checkPwdState(id)) {
      //Close
      this.closePwd(id)
    } else {
      //Open
      this.openPwd(id)
    }
  }

  open(id) {
    this.openIds.push(id)
  }

  close(id) {
    for (let i = 0; i < this.openIds.length; i++) {
      if (this.openIds[i] === id) {
        this.openIds.splice(i, 1)
      }
    }
  }

  openPwd(id) {
    this.openPwdIds.push(id)
  }

  closePwd(id) {
    for (let i = 0; i < this.openPwdIds.length; i++) {
      if (this.openPwdIds[i] === id) {
        this.openPwdIds.splice(i, 1)
      }
    }
  }

  checkState(id) {
    if (this.openIds.includes(id)) return true
    else return false
  }

  checkPwdState(id) {
    if (this.openPwdIds.includes(id)) return true
    else return false
  }


  setInputType(id) {
    if (this.checkPwdState(id)) return 'text'
    else return 'password'
  }



}
