import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  device$:Observable<any>
  id:string

  constructor(private firebaseService:FirebaseService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.id = this.route.snapshot.paramMap.get('id')
    this.device$ = this.firebaseService.getDocumentValueChanges('devices', this.id) 
  }

  save(device) {
    this.firebaseService.updateDocument('devices', device.id, device)
    .then(() => console.log('updated'))
  }

  updateEnt() {
    this.firebaseService.moveEnt()
  }

}
