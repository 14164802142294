import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-access-password',
  templateUrl: './access-password.component.html',
  styleUrls: ['./access-password.component.scss']
})
export class AccessPasswordComponent implements OnInit {

  id:string
  password:string
  migration: string

  constructor(private firebaseService:FirebaseService, private route: ActivatedRoute, private stateService:StateService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.stateService.migrationID = this.route.snapshot.paramMap.get('migration');
    const sub = this.firebaseService.getDocumentValueChanges('passwords', this.id)
    .subscribe((password:any) => {
      console.log(password)
      this.password = password.password;
      this.updateFetch()
      sub.unsubscribe()
    })
  }

  updateFetch() {
    this.firebaseService.updateDocument('passwords', this.id, {fetched: true, fetchedDate: new Date()})
    .then(() => console.log('password fetched'))
  }

}
