import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-entity-log',
  templateUrl: './entity-log.component.html',
  styleUrls: ['./entity-log.component.scss']
})
export class EntityLogComponent implements OnInit {

  @Input() id: string
  logs$: Observable<any>

  manualLog: string;

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.logs$ = this.firebaseService.getLogs$(this.id)
  }

  splitUser(user: string) {
    let split = user.split(' ')

    if (split.length > 1) {
      return split[0].substr(0, 1) + split[1].substr(0, 1)
    } else return '-'



  }

  addManualLog() {

    const data = {
      message: this.manualLog,
      type: 'message',
      timestamp: Date.now(),
      collection: 'devices',
      parentID: this.id,
    }


    this.firebaseService.log(data)
    this.manualLog = ''

  }



}
