import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  migrations$:Observable<any>

  constructor(private firebaseService:FirebaseService, private router:Router, private stateService: StateService) { }

  ngOnInit(): void {
    this.stateService.migrationID = null
    this.migrations$ = this.firebaseService.getMigrations();
  }

  openMigration(id:string) {
    this.router.navigateByUrl(`/home/${id}/dashboard`)
  }

}
