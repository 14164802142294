import { Component, OnInit } from "@angular/core";
import { auditTime } from "rxjs/operators";
import { FirebaseService } from "../../services/firebase.service";
import { LogService } from "../../services/log.service";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(
    public stateService: StateService,
    private firebaseService: FirebaseService,
    private log: LogService
  ) {}

  data: any;
  migrationID:string

  ngOnInit(): void {
    //this.log.info("Opened dashboard")
    //this.migrateData()
    this.migrationID = this.stateService.migrationID
    console.log(this.stateService.migrationID)
    this.stateService.loadBookings();
    this.stateService
      .getBookings()
      .pipe(auditTime(500))
      .subscribe((bookings) => {
        if (bookings) {
          this.data = this.sum(bookings);
        }
      });
  }

  sum(bookings) {
    const sum = {
      office: 0,
      home: 0,
      noAnswer: 0,
    };

    bookings.forEach((booking) => {
      if (!booking.location) sum.noAnswer++;
      if (booking.location == "home") sum.home++;
      if (booking.location == "office") sum.office++;
    });

    return sum;
  }

  migrateData() {
    this.firebaseService.migrateData("qb3");
  }
}
