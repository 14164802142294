import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {FirebaseService} from '../../services/firebase.service'

@Component({
  selector: 'app-password-status',
  templateUrl: './password-status.component.html',
  styleUrls: ['./password-status.component.scss']
})
export class PasswordStatusComponent implements OnInit {

  filter:string
  passwords$:Observable<any>

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.passwords$ = this.firebaseService.getCollectionSnapshot('passwords')
  }

}
