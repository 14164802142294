import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-display-user-info',
  templateUrl: './display-user-info.component.html',
  styleUrls: ['./display-user-info.component.scss']
})
export class DisplayUserInfoComponent implements OnInit {

  user$: Observable<any>

  @Input() id:string

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.user$ = this.firebaseService.getUser(this.id);
  }

}
