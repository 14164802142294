import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private auth: AngularFireAuth, private firebaseService:FirebaseService) { }


  createLog = async (message: string, type:string) => {

    const user = await this.auth.currentUser
    
    const logObject = {
      message,
      timestamp: new Date(),
      user: user.displayName,
      type

    }
    console.log(logObject)
    this.firebaseService.writeLog(logObject).then(() => console.log("log written"))
  }


  info = (message: string) => {
    this.createLog(message, 'info')
  }

  error = (message: string) => {
    this.createLog(message, 'error')
  }


  


}
