import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  portal: string

  constructor(public stateService: StateService) { }

  ngOnInit(): void {
    this.portal = this.stateService.migrationID
  }

}
