import { Component, Input, OnInit } from '@angular/core';
import {FirebaseService} from '../../services/firebase.service'

@Component({
  selector: 'app-display-password',
  templateUrl: './display-password.component.html',
  styleUrls: ['./display-password.component.scss']
})
export class DisplayPasswordComponent implements OnInit {

  @Input() id:string
  password: string
  count:number
  countDown: boolean

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
  }

  displayPassword() {

    const subscription = 
    this.firebaseService.getPasswords(this.id)
    .valueChanges()
    .subscribe((passwords:any) => {
      this.password = passwords[0].password;
      this.startCountDown(30)
      subscription.unsubscribe()
    })
    
  }

  startCountDown(seconds:number) {
    this.countDown = true;
    this.count = seconds
    const intervall = setInterval((ref) => {
      this.count--
      if (this.count === 0) {
        clearInterval(intervall)
        this.hidePassword()
      }
    }, 1000)
  }

  hidePassword() {
    this.password = undefined,
    this.countDown = false
  }

}
