import { Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import 'firebase/auth';
import { GraphService } from '../services/graph.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  innerWidth: number;
  forceClosed: boolean;
  closed: boolean;
  user$: any
  me$: Promise<any>

  editor: EditorJS

  provider = new firebase.auth.OAuthProvider('microsoft.com');

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (!this.forceClosed) {
      if (window.innerWidth > 1028) {
        this.closed = false;
      } else {
        this.closed = true;
      }
    }

  }

  constructor(
    private auth: AngularFireAuth,
    private graphService: GraphService,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService) { }

  ngOnInit(): void {

    this.stateService.migrationID = this.route.snapshot.paramMap.get('id')


    this.user$ = this.auth.user

    this.innerWidth = window.innerWidth;

    this.provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: 'a21320bb-facd-466b-81b1-df45c6bc6325',
    })




  }

  save() {
    this.editor.save()
      .then((result) => {
        console.log(result)
      })
  }

  signOut() {
    this.auth.signOut().then(() => this.router.navigateByUrl('login'))
  }

  splitUser(user: string) {
    let split = user.split(' ')

    if (split.length > 1) {
      return split[0].substr(0, 1) + split[1].substr(0, 1)
    } else return '-'



  }



  toggleSidebar() {
    if (!this.forceClosed) {
      this.forceClosed = true;
      this.closed = true;
    } else {
      this.forceClosed = false;
      this.closed = false;
    }
  }

}
