import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import { FirebaseService } from '../../services/firebase.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ActivatedRoute } from '@angular/router';
import { auditTime } from 'rxjs/operators';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  @Input() id: string;
  @Input() parentID: string;
  @Input() parentCollection: string



  editor: EditorJS;
  data: any;
  notes: Subscription
  parent$: Observable<any>
  lastUpdate: string;
  updatedBy: string;
  save: BehaviorSubject<any> = new BehaviorSubject(null)

  constructor(private firebaseService: FirebaseService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    //this.parentID = this.route.snapshot.paramMap.get('id');
    //this.parentCollection = this.route.snapshot.paramMap.get('collection');
    this.parent$ = this.firebaseService.getDocumentValueChanges(this.parentCollection, this.parentID)


    this.editor = new EditorJS({
      /** 
       * Id of Element that should contain the Editor 
       */
      holder: 'editorjs',

      /** 
       * Available Tools list. 
       * Pass Tool's class or Settings object for each Tool you want to use 
       */
      tools: {
        header: Header,
        list: List
      },
      data: this.data,
      onChange: () => { this.save.next('changed') }
    })

    this.editor.isReady.then(() => {
      if (this.parentID) {
        this.notes = this.firebaseService.getNote(this.parentID)
          .subscribe((notes: any) => {
            console.log(notes)
            if (notes.length > 0) {
              this.editor.render(notes[0].data)
              this.id = notes[0].id
              this.lastUpdate = notes[0].data.time
              this.updatedBy = notes[0].updatedBy
              this.notes.unsubscribe()
            }
          })
      }
    });

    this.save.pipe(auditTime(1000))
      .subscribe((state) => {
        console.log(state)
        if (state === "changed") {
          this.saveNotes(this.parentID)
        }
      })



  }

  sanitizeCollection(collection: string) {
    if (collection === 'devices') return 'Device'
    if (collection === 'users') return 'Users'
  }


  saveNotes(parentID: any) {
    console.log(parentID)
    this.editor.save()
      .then(data => {
        console.log(data)
        //TODO: Check if there is an ID
        if (this.id) {
          this.firebaseService.updateNoteAsync({ data: data, parentID: this.parentID, updated: Date.now() }, this.id, parentID)
            .then(doc => {
              console.log(this.id + ' Was updated!');
            })
        } else {
          this.firebaseService.createNoteAsync({ data: data, parentID: this.parentID, created: Date.now() }, parentID)
            .then(doc => {

            })
        }

      })
  }

}
