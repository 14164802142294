export const AppConfig = {
  production: false,
  environment: 'LOCAL',
  firebase: {
    apiKey: "AIzaSyBMUa-YcN5ZOrUjrU5RRLIkECD97tmprs4",
    authDomain: "migration-test-2d9a7.firebaseapp.com",
    projectId: "migration-test-2d9a7",
    storageBucket: "migration-test-2d9a7.appspot.com",
    messagingSenderId: "527238673229",
    appId: "1:527238673229:web:e10b8fc6e278162d5e7d04",
    measurementId: "G-SCWMYP8NT1"
  }
};
