import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public auth: AngularFireAuth, private router: Router) { }

  password: string;
  email: string;

  provider = new firebase.auth.OAuthProvider('microsoft.com');


  login = () => {
    this.auth.signInWithEmailAndPassword(this.email, this.password)
      .then((user) => {
        console.log(user)
        localStorage.email = user.user.email
        this.router.navigateByUrl('');
      })
  }

  ngOnInit(): void {
    this.provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: 'a21320bb-facd-466b-81b1-df45c6bc6325',
    });

    this.provider.addScope('user.read')
    this.provider.addScope('api://32f92a40-ea15-4bc9-9a51-a2c471579f08/access_as_user')

  }

  loginO365() {
    this.auth.signInWithPopup(this.provider)
      .then((result) => {
        // IdP data available in result.additionalUserInfo.profile.
        // ...

        /** @type {firebase.auth.OAuthCredential} */
        var credential: any = result.credential;

        // OAuth access and id tokens can also be retrieved:
        var accessToken = credential.accessToken;
        var idToken = credential.idToken;
        localStorage.accesToken = accessToken;
        localStorage.idToken = idToken;
        localStorage.displayName = result.user.displayName;
        console.log(result);
        this.router.navigateByUrl('/home/dashboard');

      })
      .catch((error) => {
        // Handle error.
      });
  }

}
