import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewChecked {

  logs$:Observable<any>

  constructor(private firebaseService: FirebaseService, private stateService: StateService) { }
  
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  ngOnInit(): void {
    //this.stateService.loadLogs()
    this.logs$ = this.firebaseService.loadLogs()
    this.logs$.subscribe((res) => this.scrollToBottom())

  }

  splitUser(user:string) {
    let split = user.split(' ')

    if (split.length > 1) {
      return split[0].substr(0, 1) + split[1].substr(0, 1)
    } else return '-'
    
    

  }

  statusCheck(message: string) {
    if (message.includes("DONE")) return "green";
    if (message.includes("IN PROGRESS")) return "yellow"
    if (message.includes("NOT STARTED")) return "indigo"
  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

}
