import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FirebaseService } from "../../services/firebase.service";

@Component({
  selector: "app-add-workstation",
  templateUrl: "./add-workstation.component.html",
  styleUrls: ["./add-workstation.component.scss"],
})
export class AddWorkstationComponent implements OnInit {
  deviceForm = new FormGroup({
    name: new FormControl(""),
    type: new FormControl(""),
    brand: new FormControl(""),
    model: new FormControl(""),
    hostName: new FormControl(""),
    teamviewerID: new FormControl(""),
    assignedUser: new FormControl(""),
    assignedUserID: new FormControl(""),
    companyID: new FormControl(""),
  });

  constructor(private firebaseService: FirebaseService) {}

  ngOnInit(): void {}

  onSubmit() {
    const value = this.deviceForm.value;
    const device = {
      name: value.name,
      type: value.type,
      brand: value.brand,
      model: value.model,
      hostName: value.hostName,
      teamviewerID: value.teamviewerID,
      assignedTo: {
        name: value.assignedUser,
        id: value.assignedUserID,
      },
      tasklists: ["dOxH6P9ntWiceXoaDmeG"],
    };

    this.firebaseService.addDevice(device).then((res) => console.log(res));
  }

  signOut() {
    this.firebaseService.signOut();
  }
}
