import { Component, Input, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-booking-timeslot',
  templateUrl: './booking-timeslot.component.html',
  styleUrls: ['./booking-timeslot.component.scss']
})
export class BookingTimeslotComponent implements OnInit {
  @Input() timeslotID:string

  timeslot: any

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {

    if(this.timeslotID) {
      this.firebaseService.getTimeslot(this.timeslotID)
      .then((timeslot) => {
        this.timeslot = timeslot
      })

    } else {
      this.timeslot = 'No timeslot selected'
    }



  }

}
