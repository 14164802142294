import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromptService {

  promptActive: BehaviorSubject<any> = new BehaviorSubject(false)

  constructor() { }
}
