import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';

import { HomeRoutingModule } from './home/home-routing.module';
import { DetailRoutingModule } from './detail/detail-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { AccessPasswordComponent } from './pages/access-password/access-password.component';
import { Qb3Component } from './pages/qb3/qb3.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'timeslot/:id', component: TimeslotComponent
  },

  {
    path: 'getpwd/:migration/:id', component: AccessPasswordComponent
  },
  {
    path: 'qb3', component: Qb3Component
  },
  {
    path: 'no-rights', component: NoRightsComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
    HomeRoutingModule,
    DetailRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
