import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AppConfig } from '../environments/environment';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HomeModule } from './home/home.module';
import { DetailModule } from './detail/detail.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';

import { FirebaseService } from './services/firebase.service';
import { LogService } from './services/log.service';
import { GraphService } from './services/graph.service';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { usersReducer } from './ngrx/reducers/users.reducer';
import { deviceReducer } from './ngrx/reducers/devices.reducer';
import { logReducer } from './ngrx/reducers/log.reducer';
import { bookingsReducer } from './ngrx/reducers/bookings.reducer';
import { UsersEffects } from './ngrx/effects/users.effects';
import { DevicesEffects } from './ngrx/effects/devices.effects';
import { LogEffects } from './ngrx/effects/log.effects';
import { BookingsEffects } from './ngrx/effects/bookings.effects';
import { StateService } from './services/state.service';
import { DisplayPasswordComponent } from './components/display-password/display-password.component';
import { AccessPasswordComponent } from './pages/access-password/access-password.component';
import {PromptService} from './services/prompt.service';
import { InProgressComponent } from './component/in-progress/in-progress.component';
import { PasswordStatusComponent } from './pages/password-status/password-status.component';
import { StartComponent } from './pages/start/start.component';
import { Qb3Component } from './pages/qb3/qb3.component'



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LoginComponent, TimeslotComponent, NoRightsComponent, AccessPasswordComponent, Qb3Component],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    HomeModule,
    DetailModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(AppConfig.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      users: usersReducer,
      devices: deviceReducer,
      logs: logReducer,
      bookings: bookingsReducer
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: AppConfig.production }),
    EffectsModule.forRoot([UsersEffects, DevicesEffects, LogEffects, BookingsEffects])
  ],
  providers: [FirebaseService, GraphService, StateService, PromptService, LogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
