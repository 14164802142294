import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  users$:Observable<any>
  constructor(private firebaseService: FirebaseService, private stateService:StateService) { }

  filter:string

  ngOnInit(): void {
    // Load users to make sure we can access them
    this.stateService.loadUsers()
    //this.users$ = this.firebaseService.getCollectionSnapshot('users')
    this.users$ = this.stateService.getUsers()
  }

}
