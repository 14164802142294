import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { types } from './log.types'
import { map, switchMap, mergeMap, mergeMapTo, flatMap } from 'rxjs/operators';
import firebase from 'firebase'
import { StateService } from './state.service';
import { Console } from 'console';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private fireStore: AngularFirestore, private auth: AngularFireAuth, private router: Router, private stateService: StateService) { }


  getCollectionSnapshot = (collection: string) => {
    console.log(`migrations/${this.stateService.migrationID}/${collection}`)
    const colRef = this.fireStore.collection(`migrations/${this.stateService.migrationID}/${collection}`)
    return colRef.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, collection, ...data };
        })
      )
    );
  }

  getMigrations = () => {
    const colRef = this.fireStore.collection(`migrations`)
    return colRef.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getDocumentValueChanges = (collection: string, document: string) => {
    return this.fireStore
      .collection(`migrations/${this.stateService.migrationID}/${collection}`)
      .doc(document)
      .valueChanges()
      .pipe(
        map((response: any) => {
          return { ...response, id: document, collection }
        })
      );
  }

  getDocument = (documentPath: string) => {
    return this.fireStore
      .doc(`migrations/${this.stateService.migrationID}/${documentPath}`)
      .valueChanges()
      .pipe(
        map((response: any) => {
          return { ...response }
        })
      );
  }

  getUser = (id: string) => {
    return this.fireStore.doc(`migrations/${this.stateService.migrationID}/users/${id}`).get()
  }

  getBookings = (id: string) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/bookings`, (ref) => ref.where('userID', '==', id))
  }


  getPasswords = (id: string) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/passwords`, (ref) => ref.where('userID', '==', id))
  }

  getTimeslot = (id :string) => new Promise((resolve, reject) => {
    this.fireStore.doc(`migrations/${this.stateService.migrationID}/timeslots/${id}`).get().subscribe(timeslot => {
      if (timeslot) {
        resolve(timeslot.data())
      }
      else {
        reject('No data')
      }
      
    })
  })


  updateBooking(bookingID: string, slotID: string, data) {
    this.fireStore.collection(`migrations/${this.stateService.migrationID}/bookings`)
      .doc(bookingID)
      .update(data)
      .then(() => {
        console.log('booking updated')
      })
  }

 timeSlotsStatus = () => new Promise((resolve, reject) => {

  let status = {}

  this.getCollectionSnapshot(`migrations/${this.stateService.migrationID}/bookings`)
  .subscribe((t:any) => {
    status = {}
   for (let i = 0; i < t.length; i++) {
     const booking = t[i]
    if (booking.slotID) {
      if (status[booking.slotID]) status[booking.slotID]++
      else status[booking.slotID] = 1
    }
   }
   resolve(status)
  })
 })


 updateDoc = (path, data) => {

  return this.fireStore.doc(`migrations/${this.stateService.migrationID}/${path}`).update(data)

 }



  addDevice = async (device: any) => {

    const addDevice$ = await this.fireStore
      .collection(`migrations/${this.stateService.migrationID}/devices`)
      .doc(device.hostName)
      .set(device)
      .then(() => {
        this.fireStore.doc(`migrations/${this.stateService.migrationID}/tasklists/dOxH6P9ntWiceXoaDmeG/entities/${device.hostName}`)
        .set({type: 'device'})
        .then(() => console.log('Tasklist updated.'))
      })

    return addDevice$
  }

  
  getData$(id:string, collection:string) {
    console.log(id)
    return this.fireStore.collection<any>(`migrations/${id}/${collection}`).stateChanges()
  }

  writeLog(data: any) {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/logs`).doc(Date.now().toString()).set(data)
  }

  loadLogs() {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/logs`, (ref) => ref.orderBy("timestamp", "asc").limit(100)).valueChanges()
  }


  addCustomMessage = (message: string) => {
    const data = {
      message,
      type: types.message,
      timestamp: Date.now(),
      collection: 'messages',
      parentID: 'NA'
    }

    this.log(data);
  }


  log = async (data: any) => {

    console.log('adding log!')

    const logItem = { ...data, user: localStorage.displayName }

    const addLog$ = await this.fireStore
      .collection('systemLog')
      .add(logItem)

    return addLog$

  }

  addCustomLog = (data:any) => {
    this.fireStore.collection('systemLog').add(data)
    .then(() => console.log('logg added'))
  }

  signOut() {
    this.auth.signOut()
      .then(() => {
        this.router.navigateByUrl('')
      })

  }


  getAllLogs$ = () => {
    return this.fireStore.collection('systemLog', ref => ref.orderBy('timestamp', 'desc').limit(20)).stateChanges()
  }

  getLogs$ = (ID: string) => {
    return this.fireStore.collection('systemLog', ref => ref.where('parentID', '==', ID).orderBy('timestamp', 'desc').limit(5)).valueChanges()

  }


  getDevices = () => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/devices`).valueChanges()
  }

  createNote = (data: any, parent: any) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/notes`).add(data)

  }

  createDocument = (collection: string, data:any) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/${collection}`).add(data)
  }

  createDocumentPath = (path: string, data:any) => {
    return this.fireStore.doc(path).set(data)
  }

  updateDocument = (collection:string, doc:string, data:any) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/${collection}`).doc(doc).update(data)
  }

  updateDocumentPath = (path:string, data:any) => {
    return this.fireStore.doc(`migrations/${this.stateService.migrationID}/${path}`).update(data)
  }
  addTasklist = (taskID:string, deviceID:string) => {
    return this.fireStore
    .collection(`migrations/${this.stateService.migrationID}/devices`)
    .doc(deviceID)
    .update({
      tasklists: firebase.firestore.FieldValue.arrayUnion(taskID)
    })
  }

  removeTaskList = (taskID:string, deviceID:string) => {
    return this.fireStore
    .collection(`migrations/${this.stateService.migrationID}/devices`)
    .doc(deviceID)
    .update({
      tasklists: firebase.firestore.FieldValue.arrayRemove(taskID)
    })
  }

  createNoteAsync = async (data, parentID: any) => {
    this.fireStore.collection(`migrations/${this.stateService.migrationID}/notes`).add({ ...data, updatedBy: localStorage.displayName })
      .then((note) => {

        const setType = (collection: string) => {
          if (collection === 'devices') return 'device'
        }

      })

  }

  updateNote = (data: any, ID: string) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/notes`).doc(ID).update(data)

  }

  updateNoteAsync = async (data: any, ID: string, parent: any) => {
    console.log(data)
    console.log(parent)

    const setType = (collection: string) => {
      if (collection === 'devices') return 'device'
    }

    const request = await this.fireStore.collection(`migrations/${this.stateService.migrationID}/notes`).doc(ID).update({ ...data, updatedBy: localStorage.displayName }).then((note: any) => {

    })

    return request
  }

  getNote = (id: string) => {
    return this.fireStore.collection(`migrations/${this.stateService.migrationID}/notes`, (ref) => ref.where('parentID', '==', id))
      .snapshotChanges().pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }



  migrateData = (desc:string) => {

    const moveCollection = (data:any, id:string, collection:string) => {
      this.fireStore
      .doc(`migrations/${desc}/${collection}/${id}`)
      .set(data)
      .then(() => console.log(`document moved to: migrations/${desc}/${collection}/${id}`))
    }

    const getCollection = (collection:string) => {

      this.fireStore.collection(`migrations/${this.stateService.migrationID}/${collection}`).get().subscribe((docs) => {
        docs.forEach((doc) => {
          moveCollection(doc.data(), doc.id, collection)
        })
      })


    }

    getCollection('tasklists')



  }

  moveEnt() {

    this.fireStore.collection('tasklists/dOxH6P9ntWiceXoaDmeG/entities/')
    .get()
    .subscribe((docs) => { 
      docs.forEach((doc) => {
        this.fireStore.doc(`migrations/dryit/tasklists/dOxH6P9ntWiceXoaDmeG/entities/${doc.id}`).set(doc.data()).then(() => console.log('updated'))
      })
    })

  }



}
