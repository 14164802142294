import { Component, Input, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-task-list-summer',
  templateUrl: './task-list-summer.component.html',
  styleUrls: ['./task-list-summer.component.scss']
})
export class TaskListSummerComponent implements OnInit {

  @Input() migrationID:string

  tasklist: any
  entities: any
  sum: any
  deviceChartData

  constructor(private firebaseService: FirebaseService, private stateService:StateService) { }

  ngOnInit(): void {
    console.log(this.migrationID)
    this.firebaseService.getDocument(`tasklists/dOxH6P9ntWiceXoaDmeG`)
      .subscribe(res => {
        this.tasklist = res
      })

    this.firebaseService.getCollectionSnapshot(`tasklists/dOxH6P9ntWiceXoaDmeG/entities`)
      .subscribe((res) => {
        this.entities = res
        console.log(this.sumEntities())
      })

  }

  sumEntities() {

    this.sum = {
      notStarted: 0,
      inProgress: 0,
      done: 0,
      started: 0,
      deviceDone: 0,
      deviceNotStarted: 0
    }



    this.entities.forEach((list) => {
      list.sum = {
        notStarted: 0,
        inProgress: 0,
        done: 0,
        deviceDone: 0
      }
      const notStarted = () => {
        list.sum.notStarted++
        this.sum.notStarted++
      }

      const inProgress = () => {
        list.sum.inProgress++
        this.sum.inProgress++
      }

      const done = () => {
        list.sum.done++
        this.sum.done++
      }

      const started = () => {
        list.started = true
        this.sum.started++
      }

      const deviceDone = () => {
        list.deviceDone = true
        this.sum.deviceDone++
      }

      const deviceNotStarted = () => {
        list.started = false
        this.sum.deviceNotStarted++
      }

      this.tasklist.tasks.forEach(task => {
        if (list.tasks) {
          if (list.tasks[task.id]) {
            if (list.tasks[task.id].status === 'not started') notStarted()
            if (list.tasks[task.id].status === 'in progress') inProgress()
            if (list.tasks[task.id].status === 'done') done()
          } else {
            notStarted()
          }
        } else {
          notStarted()
        }
      });

      if (list.sum.inProgress === 0 && list.sum.done === 0) deviceNotStarted()
      else started()

      if (list.sum.inProgress === 0 && list.sum.notStarted === 0 && list.sum.done > 0) deviceDone()


    })

    this.deviceChartData = this.ChartData()

  }

  ChartData() {
    return [
      { data: [this.sum.deviceNotStarted], label: 'Not started' },
      { data: [this.sum.started - this.sum.deviceDone], label: 'In progress' },
      { data: [this.sum.deviceDone], label: 'Done' },
    ]
  }

}
