import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {

  @Input() id:string

  user$:Observable<any>

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {

    this.user$ = this.firebaseService.getDocumentValueChanges('users', this.id)
    
  }

  save(user) {
    this.firebaseService.updateDocument('users', user.id, user)
    .then(() => console.log('updated'))
  }

}
