import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { FirebaseService } from "../../services/firebase.service";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
})
export class TaskComponent implements OnInit {
  @Input() label: string;
  @Input() id: string;
  @Input() desc: string;
  @Input() status: string;
  @Input() user: string;
  @Input() hook: boolean;
  @Input() deviceID: string;
  @Input() divider: boolean;
  @Input() index: number;

  @Output() save: EventEmitter<any> = new EventEmitter();
  icon: string;

  // status: string = 'not started'
  statusList: string[] = ["not started", "in progress", "done"];
  userList: string[] = ["Jesper J", "Juha J", "Terho T", "Christian G"];

  showStatusPane: boolean;
  showUserPane: boolean;

  constructor(
    private fns: AngularFireFunctions,
    private stateService: StateService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    this.icon =
      "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z";

    if (!this.status) {
      this.status = "not started";
    }

    if (!this.user) {
      this.user = "Not assigned";
    }

    console.log(this.status);
  }

  sendMessage(phone) {
    const callable = this.fns.httpsCallable("sendText");
    callable({
      phone,
      message: "Hej! Din dator är nu migrerad. Du kommer att få ett nytt sms med information om hur du tillhandahåller ditt lösenord så fort vi avslutat migreringen för samtliga användare.",
    }).subscribe((res) => console.log(res));
  }

  getLocation() {

    let prompt = false

    this.stateService.loadDevices();
    const subscription = this.stateService
      .getDevice(this.deviceID)
      .subscribe((device) => {
        if (device) {
          console.log(device);
          const userID = device.assignedTo.id;
          //Get booking
          this.firebaseService
            .getBookings(userID)
            .valueChanges()
            .subscribe((bookings) => {
              const booking: any = bookings[0];
              if (booking.location === "home") {

                if(!prompt) {
                  console.log("time to send a message");
                  prompt = true
                if (confirm("This will send a message to the user, cancel to skip sending message")) {
                  this.sendMessage(booking.phone)
                  subscription.unsubscribe()
                }
                }

                
              } else {
                console.log("computer is at office, no message sent");
                subscription.unsubscribe()
              }
            });
        }
      });
  }

  createUserLabel() {
    let displayName = localStorage.displayName;
    let split = displayName.split(" ");

    return `${split[0]} ${split[1].substr(0, 1)}`;
  }

  setStatus(status: string) {
    if (status === "done") {
    }

    this.status = status;
    this.showStatusPane = false;
    this.user = this.createUserLabel();

    this.save.emit({ status: this.status, user: this.user, label:this.label });
  }

  changeStatus() {
    console.log('changing status')
    if (this.status === "not started") this.setStatus("in progress");
    else if (this.status === "in progress") {
      if (this.hook) {
        this.getLocation();
      }
      this.setStatus("done");
    } else this.setStatus("not started");
  }
}
