import { Component, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { AddWorkstationComponent } from "../components/add-workstation/add-workstation.component";

import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  customClaims,
} from "@angular/fire/auth-guard";
import { DeviceListComponent } from "../components/device-list/device-list.component";
import { NotepadComponent } from "../components/notepad/notepad.component";
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { UsersListComponent } from "../pages/users-list/users-list.component";
import { DashboardComponent } from "../pages/dashboard/dashboard.component";
import { CrateTasklistComponent } from "../pages/crate-tasklist/crate-tasklist.component";
import { DeviceComponent } from "../pages/device/device.component";
import { ListBookingsComponent } from "../components/list-bookings/list-bookings.component";
import { PasswordStatusComponent } from "../pages/password-status/password-status.component";
import { StartComponent} from '../pages/start/start.component'

//const adminOnly = () => hasCustomClaim('admin');
const test = () =>
  pipe(
    customClaims,
    map((claims) => (claims.admin ? [""] : ["no-rights"]))
  );
const adminOnly = () =>
  pipe(
    customClaims,
    map((claims) => {
      if (!claims.admin) {
        return ["no-rights"];
      } else return true;
    })
  );

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToItems = () => redirectLoggedInTo(["items"]);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: "home/:id",
    component: HomeComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
    children: [
      { component: AddWorkstationComponent, path: "add/workstation" },
      { component: CrateTasklistComponent, path: "add/tasklist" },
      { component: DeviceListComponent, path: "list/devices" },
      { component: UsersListComponent, path: "list/users" },
      { component: ListBookingsComponent, path: "list/bookings" },

      { component: DashboardComponent, path: "dashboard" },
      { component: DeviceComponent, path: "device/:id" },
      { component: PasswordStatusComponent, path: "status/password" },
    ],
  },
  {
    path: "notepad",
    component: NotepadComponent,
  },
  {
    path: "start",
    component: StartComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
