import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../services/firebase.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  @Input() tasklistID: string
  @Input() dev: any

  device: any

  tasklist$: Observable<any>
  tasks: any

  constructor(private firebaseService: FirebaseService, private log: LogService) { }

  ngOnInit(): void {
    console.log(this.dev)
    this.tasklist$ = this.firebaseService.getDocumentValueChanges('tasklists', this.tasklistID)
    this.tasklist$.subscribe((res: any) => {
      this.tasks = res.tasks
    })
    this.getDevice()
  }


  getDevice() {
    this.firebaseService.getDocument(`tasklists/${this.tasklistID}/entities/${this.dev.id}`)
      .subscribe((device) => {
        console.log(device)
        if (!device.tasks) {
          console.log('no task')
          device.tasks = {}
        }

        this.tasks.forEach((task) => {
          if (!device.tasks[task.id]) {
            device.tasks[task.id] = { status: 'not started' };
          }
        })

        this.device = device
        console.log(this.device)
      })
  }

  saveTask(event, id: string) {
    console.log(event)
    this.device.tasks[id] = event;
    console.log(this.device)
    this.firebaseService
    .updateDocumentPath(`tasklists/${this.tasklistID}/entities/${this.dev.id}`, this.device)
      .then(() => {
        this.log.info(`Changed status to ${event.status.toUpperCase()} for task "${event.label}" for device "${this.dev.hostName}" assigned to user: ${this.dev.assignedTo.name}`)
      
    })
  }




}
