import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';

import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { UsersListComponent } from '../pages/users-list/users-list.component';
import { AddWorkstationComponent } from '../components/add-workstation/add-workstation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LogsComponent } from '../components/logs/logs.component';
import { AddMessageComponent } from '../components/add-message/add-message.component';
import { NgPipesModule } from 'ngx-pipes';

import { DeviceListComponent } from '../components/device-list/device-list.component'
import { NotepadComponent } from '../components/notepad/notepad.component';
import { TaskComponent } from '../components/task/task.component';
import { EntityLogComponent } from '../components/entity-log/entity-log.component';
import { TaskListComponent } from '../components/task-list/task-list.component';
import { DisplayUserInfoComponent } from '../components/display-user-info/display-user-info.component';
import { UserBookingComponent } from '../components/user-booking/user-booking.component';
import { BookingTimeslotComponent } from '../components/booking-timeslot/booking-timeslot.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { PieChartComponent } from '../components/pie-chart/pie-chart.component';
import { ChartsModule } from 'ng2-charts';
import { CrateTasklistComponent } from '../pages/crate-tasklist/crate-tasklist.component';
import { TaskListSummerComponent } from '../components/task-list-summer/task-list-summer.component';
import { BarChartComponent } from '../components/bar-chart/bar-chart.component';
import { StatsEntryComponent } from '../components/stats-entry/stats-entry.component';
import { DeviceComponent } from '../pages/device/device.component';
import { ContactInformationComponent } from '../components/contact-information/contact-information.component';
import { ListBookingsComponent } from '../components/list-bookings/list-bookings.component';
import { DisplayPasswordComponent } from '../components/display-password/display-password.component';
import { InProgressComponent } from '../component/in-progress/in-progress.component';
import { PasswordStatusComponent } from '../pages/password-status/password-status.component';
import { StartComponent} from '../pages/start/start.component'

@NgModule({
  declarations: [
    HomeComponent,
    StartComponent,
    NavbarComponent,
    UsersListComponent,
    AddWorkstationComponent,
    LogsComponent,
    AddMessageComponent,
    DeviceListComponent,
    NotepadComponent,
    TaskComponent,
    EntityLogComponent,
    TaskListComponent,
    DisplayUserInfoComponent,
    UsersListComponent,
    UserBookingComponent,
    BookingTimeslotComponent,
    DashboardComponent,
    PieChartComponent,
    CrateTasklistComponent,
    TaskListSummerComponent,
    BarChartComponent,
    StatsEntryComponent,
    DeviceComponent,
    ContactInformationComponent,
    ListBookingsComponent,
    DisplayPasswordComponent,
    InProgressComponent,
    PasswordStatusComponent
  ],
  imports: [CommonModule, SharedModule, HomeRoutingModule, ReactiveFormsModule, NgPipesModule, ChartsModule]
})
export class HomeModule { }
