import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  provider = new firebase.auth.OAuthProvider('microsoft.com');
  baseURL: string = 'https://graph.microsoft.com/v1.0/'
  

  constructor(private http: HttpClient, private auth: AngularFireAuth) {

    this.provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: 'a21320bb-facd-466b-81b1-df45c6bc6325',
    })

   }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.accesToken}`
    })
  };


  test() {
    return this.http.get('https://google.se')
  }


  me() {
    return this.http.get('https://graph.microsoft.com/v1.0/me', this.httpOptions)
  }

  withRefresh$ = (path:string) => new Promise((resolve, reject) => {

    const url = this.baseURL + path

    const Options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.accesToken}`
      })
    };


    this.http.get(url, Options)
    .subscribe((res:any) => {
      if (res) {
        resolve(res)
      } else {
        this.auth.currentUser.then(user => {
          user.reauthenticateWithPopup(this.provider)
            .then(result => {
              var credential: any = result.credential;

              // OAuth access and id tokens can also be retrieved:
              var accessToken = credential.accessToken;
              var idToken = credential.idToken;
              localStorage.accesToken = accessToken;
              localStorage.idToken = idToken;
              this.http.get(url, Options)
              .subscribe((res) => {
                resolve(res)
              })
            })
        })
      }
    })
  })

}
